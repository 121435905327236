.whatsapp-float {
  position:fixed;

  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;

  background-color: #25d366;
  border-radius: 50px;
  color: #FFF;
  font-size: 30px;
  margin-top:16px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}
