.pointer {
  cursor: pointer;
}

.effect-none {
  &:before {
    content: '';
    background-color: transparent;
    height: 0;
    width: 0;
  }

  &:after {
    content: '';
    background-color: transparent;
    height: 0;
    width: 0;
  }
}