//r eset active etc style:
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn, .pagination a, .pagination .current {
  border: none;
  text-decoration: none;
  border-radius: 0;

  display: inline-block;
  text-align: center;
  vertical-align: middle;

  font-family: $font-family-base;
  font-weight: 400;
  font-size: 18px;
  line-height: 40px;
  padding: 0px 30px;
  transition: 0.3s;
}

.pagination a, .pagination .current {
  margin-bottom: 10px;
}

.btn-lg {
  height: 64px;
  padding: 12px 50px;
}

.btn-primary, .pagination .current {
  color: $inverse-color;
  background: $primary-color;

  &:hover, &:focus, &:active {
    color: $inverse-color;
    background: darken($primary-color, 20%);
  }
}

.btn-secondary, .btn-default, .pagination a {
  color: $inverse-color;
  background-color: $secondary-color;

  &:hover, &:focus, &:active {
    color: $inverse-color;
    background: darken($secondary-color, 20%);
  }
}

.btn-tertiary {
  color: $inverse-color;
  background-color: $tertiary-color;

  &:hover, &:focus, &:active {
    color: $inverse-color;
    background: darken($tertiary-color, 20%);
  }
}
