@font-face {
  font-family: 'proxima_novaregular';
  src: url('../../fonts/proxima_nova_font-webfont.woff2') format('woff2'),
  url('../../fonts/proxima_nova_font-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'balooregular';
  src: url('../../fonts/Baloo-Regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
