.text-inverse-color {
  color: $inverse-color !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-tertiary {
  color: $tertiary-color !important;
}

.text-color {
  color: $text-color !important;
}