.glide {
  background-color: #000000;
  text-align: center;

  .cta-container {
    position: absolute;
    top: 150px;
    left: 150px;

    h1, h2, h3, h4, h5, h6, p {
      color: $inverse-color;
      text-shadow: 1px 1px 1px rgb(0 0 0 / 80%);
    }

    h1, h2, h3, h4, h5, h6 {
      font-size: 72px;
      line-height: 72px;
    }

    p {
      font-size: 24px;
    }
  }
}
