.card-round-lg-shadow-lg {
  box-shadow: 0 3px 35px 0 rgb(0 0 0 / 9%);
  background: #fff;
  border-radius: 24px;
  padding: 30px;
}

.card-tour {
  border: none;

  a {
    color: $text-color;
  }

  figure {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    position: relative;
  }

  .card-img-top {
    border-radius: 0;

    transition: all .6s ease-in-out 0s;
    -webkit-transition: all .6s ease-in-out 0s;
    transform: scale(1) rotate(0);
    -webkit-transform: scale(1) rotate(0);
  }

  .card-body {
    padding: 15px 0 0 0;
  }

  .card-title {
    margin-bottom: 0;
  }

  // hover effects
  &:hover {
    cursor: pointer;

    .card-img-top {
      //filter: brightness(80%);
      opacity: .9;

      transform: scale(1.1) rotate(2deg);
      -webkit-transform: scale(1.1) rotate(2deg);
    }

    a {
      color: $primary-color;
      text-decoration: none;
    }
  }

  .days {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 60px;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: rgba(41,41,41,.2);
    backdrop-filter: blur(50px);
    color: #fff;
    border-top-right-radius: 1px;

    :first-child {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      letter-spacing: 0;
    }

    :last-child {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: .03em;
    }
  }
}