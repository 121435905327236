.navbar {
  -webkit-box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
  padding: 0;
}

.navbar .navbar-brand {
  line-height: 82px;
  padding: 0;
  margin-left: 50px;
  margin-right: 50px;

  img {
    height: 62px;
  }
}

.navbar .navbar-nav .nav-item .nav-link {
  padding: 0 25px;
  height: 100%;
  line-height: 82px;
  font-size: 17px;
}

.dropdown-menu {
  color: inherit;
  font-size: inherit;
  padding: 5px 0;
  background-color: #ffffff;
}

.dropdown-item {
  padding: 0px 25px;
  line-height: 40px;
}

.navbar-toggler {
  margin-right: 25px;
}

@media only all and (min-width: 1255px) and (max-width: 1266px) {
  .navbar .navbar-brand {
    margin-left: 25px;
    margin-right: 25px;
  }
}

@media only all and (min-width: 1201px) and (max-width: 1255px) {
  .navbar .navbar-brand {
    margin-left: 0 !important;
    margin-right: 0 !important;;
  }
}

@media only all and (max-width: 1200px) {
  .navbar .navbar-brand {
    margin-left: 25px;
    margin-right: 25px;
  }

  .navbar .navbar-nav .nav-item .nav-link {
    line-height: 42px !important;
  }
}
