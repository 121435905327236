.breadcrumb-wrapper {
  background-color: $secondary-color;
  display: none;
  padding: 10px 60px;

  @include media-breakpoint-up(xl) {
    display: block;
  }
}

.breadcrumb {
  margin: 0;
  padding: 0;
  display: none;
  line-height: 18px;

  @include media-breakpoint-up(xl) {
    display: block;
  }

  > li {
    display: inline-block;
    line-height: 12px;
    margin-bottom: 0;
    position: relative;

    &:before {
      content: none;
    }

    &:first-of-type {
      padding-left: 0;
      span {
        padding-left: 0;
      }
    }

    a {
      @include transition(all 0.2s ease);
      color: $inverse-color;
      text-decoration: none;
      span {
        @include transition(all 0.2s ease);
        color: $inverse-color;
      }
    }

    span {
      @include transition(all 0.2s ease);
      color: $inverse-color;
      padding-left: 15px;
      &:hover {
        color: $primary-color;
      }
    }
    + li {
      padding-left: 6px;
      &:before {
        color: $inverse-color;
        content: ">";
        font-size: 1.3rem;
        left: 8px;
        padding: 0;
        position: absolute;
        top: 1px;
      }
    }
    &:last-of-type {
      span {
        color: $inverse-color;
        font-weight: bold;
      }
    }
  }

  .separator {
    display: none;
  }
}