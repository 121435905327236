// font size
// ---------
// month name
.calendar table.month th.month-title {
  font-size: 17px !important;
}
// day name
.calendar table.month th.day-header {
  font-size: 14px !important;;
}
// day number
.calendar table.month td.day .day-content {
  font-size: 14px !important;;
}

// disable day
// -----------
.calendar table.month td.disabled,
.calendar table.month td.disabled:hover {
  color: #cccccc !important;
}

.calendar .calendar-header {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
  border: 0;
}

.calendar .calendar-header .year-title:hover,
.calendar .calendar-header .prev:hover,
.calendar .calendar-header .next:hover{
  background: rgba(255, 255, 255, 0.2);
}

.calendar .calendar-header .year-neighbor {
  color: inherit;
  opacity: 0.7;
}

.calendar .calendar-header .year-neighbor2 {
  color: inherit;
  opacity: 0.4;
}

// size
// ----
.calendar .month-container {
  width: 24% !important;
  height: 245px !important;
}

.calendar table.month {
  width: 92% !important;
  //height: 100%;
}

// spacing/padding
// ---------------
// month name
.calendar table.month .month-title {
  padding: 8px 0px !important;
}
// day name
.calendar table.month .day-header {
  padding: 8px 0px !important;
}


// others
// ------
.calendar table.month .month-title {
  //color: #fff;
  //background-color: #343a40;
  //border-color: #454d55;
}

.calendar table.month .day-header {
  font-weight: normal;
  border-bottom: 1px solid $primary-color;
}

.calendar table.month td.day .day-content {
  padding: 5px 8px;
}

// avoid scroll
.calendar {
  overflow-x: unset !important;
}
