.bg-inverse-color {
  background-color: $inverse-color !important;
}

.bg-primary {
  background-color: $primary-color !important;
}

.bg-primary-light {
  background-color: lighten($primary-color, 50%) !important;
}

.bg-secondary {
  background-color: $secondary-color !important;
}

.bg-tertiary {
  background-color: $tertiary-color !important;
}

.bg-light {
  background-color: #EAEAEA !important;
}

.bg-dark {
  background-color: #231f20 !important;
}