.py-sm { padding-top: 20px; padding-bottom: 20px; }
.pt-sm { padding-top: 20px; }
.pb-sm { padding-bottom: 20px; }

.py-md { padding-top: 40px; padding-bottom: 40px; }
.pt-md { padding-top: 40px; }
.pb-md { padding-bottom: 40px; }

.py-lg { padding-top: 80px; padding-bottom: 80px; }
.pt-lg { padding-top: 80px; }
.pb-lg { padding-bottom: 80px; }
