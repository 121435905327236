@font-face {
  font-family: 'fontello';
  src: url('../../fonts/fontello.eot?32216412');
  src: url('../../fonts/fontello.eot?32216412#iefix') format('embedded-opentype'),
  url('../../fonts/fontello.woff2?32216412') format('woff2'),
  url('../../fonts/fontello.woff?32216412') format('woff'),
  url('../../fonts/fontello.ttf?32216412') format('truetype'),
  url('../../fonts/fontello.svg?32216412#left') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mail:before { content: '\e800'; } /* '' */
.icon-ok-circled:before { content: '\e801'; } /* '' */
.icon-location:before { content: '\e802'; } /* '' */
.icon-phone:before { content: '\e803'; } /* '' */
.icon-road:before { content: '\e804'; } /* '' */
.icon-doc:before { content: '\e805'; } /* '' */
.icon-calendar:before { content: '\e806'; } /* '' */
.icon-search:before { content: '\e807'; } /* '' */
.icon-left-open:before { content: '\e808'; } /* '' */
.icon-right-open:before { content: '\e809'; } /* '' */
.icon-left-big:before { content: '\e80a'; } /* '' */
.icon-right-big:before { content: '\e80b'; } /* '' */
.icon-ruler:before { content: '\f044'; } /* '' */
.icon-lightbulb:before { content: '\f0eb'; } /* '' */
.icon-folder-open-empty:before { content: '\f115'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-whatsapp:before { content: '\f232'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
