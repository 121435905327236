// heading tags

#header {
  h1 {
    background-color: $secondary-color;
    color: $inverse-color;
    margin: 0;
    padding: 20px 60px 30px 60px;
  }
}

h1, .h1 {
  color: $text-color;
  font-family: $font-family-title;
  font-size: 48px;
  font-weight: 500;
  line-height: 45px;
}

h2, .h2 {
  color: $text-color;
  font-family: $font-family-title;
  font-size: 36px;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 20px;
  position: relative;

  &:after {
    content: '';
    background-color: $primary-color;
    height: 4px;
    width: 100px;
    position: absolute;
    top: 45px;
    left: 0;
  }
}

h3, .h3 {
  color: $text-color;
  font-family: $font-family-title;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin-bottom: 20px;
}

h4, .h4 {
  color: $text-color;
  font-family: $font-family-title;
  font-size: 18px;
  font-weight: 500;
  line-height: 100%;
  margin-bottom: 20px;
}

.font-32 {
  font-size: 32px;
}

.font-64 {
  font-size: 64px;
}

.strong {
  font-weight: bold;
}