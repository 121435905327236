// colors
$inverse-color: #FFFFFF;
$primary-color: #0E97E2;
$secondary-color: #A8804A;
$tertiary-color: #5B809F;
$text-color: #434043;
$placeholder-color: #C7C7C7;

// fonts
$font-family-title: 'balooregular', sans-serif;
$font-family-text: sans-serif;
$font-family-input: sans-serif;
$font-family-label: sans-serif;
$font-family-light: 'balooregular', sans-serif;
$font-family-bold: 'balooregular', sans-serif;

// margin
$margin-xs: 10px;
$margin-sm: 15px;
$margin-default: 20px;
$margin-lg: 25px;
$margin-xl: 30px;

// navbar
$navbar-height: 70px;
